import { Component, OnInit } from '@angular/core';

import { CoreService } from 'src/app/shared/services/core.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  public sidebarVisible = false;

  constructor(private coreService: CoreService) { }

  async ngOnInit() {
    this.sidebarVisible = await this.coreService.isCurrentUserActive();
  }
}
